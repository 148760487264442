<template>
    <div class="MyIndex">
        <UserCard :user="user"></UserCard>
        <InfoCard class="mt-17"></InfoCard>
    </div>
</template>
<script>
    import InfoCard from "@/components/InfoCard.vue";
    import UserCard from "@/components/UserCard.vue";
    export default {
        name: "MyIndex",
        components: {
            InfoCard,
            UserCard,
        },

        computed: {
            user: function () {
                return this.$store.state.user;
            },
        },

        mounted() {
            window.localStorage.setItem("myNavActive", -1);
            this.$store.commit("switchMyNav", -1);
            this.$https.get("/api/qy_wx_userinfo").then((res) => {
                window.localStorage.setItem("user", JSON.stringify(res.data));
                this.$store.commit("setUser");
            });
        },
    };
</script>

<style lang="less" scoped>
.MyIndex {
    width: 100%;
}
</style>